<template>
  <div class="wrap-edit search-view">
    <el-card class="edit-container">
      <div :class="!isHiding?'hide1':'hide2'">
      <el-form ref="form" :model="form" label-position="left" label-width="175px" id="wrap-hide" :style="isHiding?'':'display:block'">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item prop="ssfj">
              <template #label> 申报类型
                <el-select v-model="searchtypeform.SBLX" class="m-2" placeholder="Select" size="large">
                  <el-option v-for="item in searchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <el-select filterable class="input-width" v-model="form.SBLX">
                <el-option v-for="item in sblxs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="AJH">
              <template #label> &nbsp;&nbsp;&nbsp;案卷号
                <el-select v-model="searchtypeform.AJH" class="m-2" placeholder="Select" size="large">
                  <el-option v-for="item in searchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <el-input v-model="form.AJH"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="AJNF">
              <template #label> 案卷年份
                <el-select v-model="searchtypeform.AJNF" class="m-2" placeholder="Select" size="large">
                  <el-option v-for="item in searchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <el-input v-model="form.AJNF"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="ZBH">
              <template #label> &nbsp;&nbsp;&nbsp;总编号
                <el-select v-model="searchtypeform.ZBH" class="m-2" placeholder="Select" size="large">
                  <el-option v-for="item in searchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <el-input v-model="form.ZBH"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="XMMC">
              <template #label> 项目名称
                <el-select v-model="searchtypeform.XMMC" class="m-2" placeholder="Select" size="large">
                  <el-option v-for="item in searchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <el-input v-model="form.XMMC"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="SPBM">
              <template #label> 主办科室
                <el-select v-model="searchtypeform.SPBM" class="m-2" placeholder="Select" size="large">
                  <el-option v-for="item in searchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <InputTree :bm="form.SPBM" @getInput="getInput" :stepId="'bm'"></InputTree>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="JSDW">
              <template #label> 建设单位
                <el-select v-model="searchtypeform.JSDW" class="m-2" placeholder="Select" size="large">
                  <el-option v-for="item in searchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <el-input v-model="form.JSDW"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="JSWZ">
              <template #label> 建设位置
                <el-select v-model="searchtypeform.JSWZ" class="m-2" placeholder="Select" size="large">
                  <el-option v-for="item in searchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <el-input v-model="form.JSWZ"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="taskendusername">
              <template #label> &nbsp;&nbsp;&nbsp;办结人
                <el-select v-model="searchtypeform.taskendusername" class="m-2" placeholder="Select" size="large">
                  <el-option v-for="item in searchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <InputTree :bm="form.taskendusername" :stepId="'0'" @getInput="getInput3" ref="UserTree3"></InputTree>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="taskendtime">
              <template #label> 办结时间
                <el-select v-model="searchtypeform.taskendtime" class="m-2" placeholder="Select" size="large" @change="taskendtimechange">
                  <el-option v-for="item in timesearchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <div v-show="startshow">
                <el-date-picker v-model="beginTime" placeholder="起始日期"></el-date-picker>
              </div>
              <span v-show="tageshow" style="margin: 0px 10px"> 至 </span>
              <div v-show="endshow">
                <el-date-picker v-model="endTime" placeholder="截止日期"></el-date-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="JJRXM">
              <template #label> &nbsp;&nbsp;&nbsp;受理人
                <el-select v-model="searchtypeform.JJRXM" class="m-2" placeholder="Select" size="large">
                  <el-option v-for="item in searchtype" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </template>
              <InputTree :bm="form.JJRXM" :stepId="'0'" @getInput="getInput2" ref="UserTree"></InputTree>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
       </div>
      <div class="buttons">
        <el-button type="primary" @click="search(false)">查询</el-button>
        <el-button type="info" @click="reset">重置</el-button>
        <el-button @click="handleHide" type="text" class="el-icon-s-fold" :class="{ 'is-collapse': isHiding }"></el-button>
      </div>
      <PublicTable ref="publicTable" :fixedHeight="462" :isFixed="isHiding" :isSortable="true" @changeScroll="changeScroll">
        <template #td_ZBH="scope">
          <span class="tes" @click="opentask(scope.$index, scope.row)">{{
            scope.row.ZBH
          }}</span>
        </template>
        <template #td_JJRQ="scope">
          <span>{{ scope.row.JJRQ!=null&&scope.row.JJRQ!=undefined?scope.row.JJRQ.split("T")[0]:'' }}</span>
        </template>
        <template #td_taskendtime="scope">
          <span>{{ scope.row.taskendtime?.split("T")[0] }}</span>
        </template>
        <template #td_WorkTime="scope">
          <div v-if="limitTime(scope.row)==2">
            <span style="color:greenyellow">正常完成</span>
          </div>
          <div v-else-if="limitTime(scope.row)>2">
            <span style="color:greenyellow">正常</span>
          </div>
          <div v-else-if="limitTime(scope.row)<0">
            <span style="color:red">已超期</span>
          </div>
          <div v-else-if="limitTime(scope.row)>0">
            <span style="color:orange">即将超期</span>
          </div>
          <div v-else>
            <span style="color:greenyellow">正常</span>
          </div>
        </template>
        <template #td_Id="scope">
          <el-button size="mini" type="text" @click="opentask(scope.$index, scope.row)">打开</el-button>
        </template>
      </PublicTable>
    </el-card>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import PublicTable from "@/components/table/PublicTable.vue";
import InputTree from '@/components/Input/InputTree.vue';
import dayjs from 'dayjs';
export default {
  components: {
    PublicTable,
    InputTree,
  },
  data() {
    return {
      form: {
        SBLX: "",
        AJH: "",
        AJNF: "",
        ZBH: "",
        XMMC: "",
        SPBM: "",
        JSDW: "",
        JSWZ: "",
        taskendusername: "",
        taskendtime: "",
        JJRXM: "",
      },
      searchtypeform: {
        SBLX: "=",
        AJH: "like",
        AJNF: "=",
        ZBH: "like",
        XMMC: "like",
        SPBM: "=",
        JSDW: "like",
        JSWZ: "like",
        taskendusername: "=",
        taskendtime: ">=",
        JJRXM: "=",
      },
      queryid: 'IntegratedQue_new',
      ssfjs: [{ value: "", label: "请选择" }],
      sblxs: [{ value: "", label: "请选择" }],
      holiday: [],
      searchtype: [{ value: "=", label: "等于" }, { value: "like", label: "包含" }],
      timesearchtype: [{ value: ">=", label: "大于等于" }, { value: "<=", label: "小于等于" }, { value: ">=<=", label: "介于" }],
      startshow: true,
      endshow: false,
      tageshow: false,
      beginTime: '',
      endTime: '',
      isHiding: false,
      scrollTop: 0,
    };
  },
  methods: {
    handleHide(){
      this.isHiding = !this.isHiding;
      let hide = document.getElementById('wrap-hide');
      if(this.isHiding){
        setTimeout(() => {
          hide.style.display = 'none';
        }, 301);
      }else{
        this.$refs.publicTable.changeTableHeight();
        hide.style.display = 'block';
      }
    },
    taskendtimechange(val) {
      if (val == '>=') {
        this.startshow = true
        this.endshow = false
        this.tageshow = false
      }
      if (val == '<=') {
        this.startshow = false
        this.tageshow = false
        this.endshow = true
      }
      if (val == '>=<=') {
        this.startshow = true
        this.endshow = true
        this.tageshow = true
      }
      this.beginTime= ''
      this.endTime= ''
    },
    getDate(date) {
      var a = date
      a = dayjs(date).format('YYYY-MM-DD')
      return a
    },
    getInput(value) {
      this.form.SPBM = value
    },
    getInput2(value) {
      this.form.JJRXM = value
    },
    getInput3(value) {
      this.form.taskendusername = value
    },
    async fetch() {
      try {
        await this.getssfjs();
        await this.getsblxs();
      } catch (error) {
        console.log(error);
      }
    },
    async getssfjs() {
      return this.$ajax
        .get(this.$appConfig.apiUrls.ywxtApi + "/api/Dictionary/Getssfj")
        .then((response) => {
          if (response.data.IsSuccess) {
            response.data.Data.forEach((value) => {
              this.ssfjs.push({ value: value.code, label: value.code_value });
            });
          } else {
            throw response.data.ErrorMessage;
          }
        });
    },
    async getsblxs() {
      return this.$ajax
        .get(this.$appConfig.apiUrls.flowApi + "/api/WorkFlow/GetWorkFlowList")
        .then((response) => {
          if (response.data.IsSuccess) {
            for (let key in response.data.Data) {
              this.sblxs.push({ value: key, label: response.data.Data[key] });
            }
          } else {
            throw response.data.ErrorMessage;
          }
        });
    },
    search(type) {
      console.log(type);
      if (type) {
        let conditions = [
          {
            IsCondition: true,
            Key: "taskendtime",
            LogicalOperator: "and",
            Operator: "=",
            Value: "0-0",
          }
        ]
        this.$refs.publicTable.getLoadData(this.queryid, conditions);
      } else {
        this.$refs.publicTable.getLoadData(this.queryid, this.conditions, { pageNum: 1, pageSize: 30 });
      }
    },
    changeScroll(){
      let dom = document.getElementsByClassName("el-table__body-wrapper")[0]
      dom.removeEventListener("scroll", this.lazyLoad);
      this.$nextTick(() => {
        dom.addEventListener("scroll", this.lazyLoad);
      })
    },
    lazyLoad(){
      let dom = document.getElementsByClassName("el-table__body-wrapper")[0]
      this.scrollTop = dom.scrollTop;
    },
    reset() {
      this.$refs.form.resetFields();
      this.form.SBLX = '';
      this.beginTime= ''
      this.endTime= ''
      this.search();
    },
    opentask(index, row) {
      console.log(row)
      this.$ajax
        .get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/GetTaskIdByInstanceId?InstanceId=" + row.Id)
        .then((response) => {
          if (response.data.IsSuccess) {
            row.taskid = response.data.Data
            this.$router.push({ path: '/buslist/flowinfo', query: { row: JSON.stringify(row) } })
          } else {
            this.$router.push({ path: "/bjsx/info", query: { row: JSON.stringify(row) } });
          }
        });
    },
    isOvertime(jjrq, gt, lt, taskendtime) {

      if (jjrq && (gt || lt)) {
        const jjsj = new Date(jjrq)
        let jzsj = jjsj.setDate(jjsj.getDate() + (lt ? lt : gt))
        if (taskendtime) {
          return jzsj < new Date(taskendtime)
        } else {
          return jzsj < new Date()
        }
      } else {
        return false
      }
    },
    limitTime(row) {
      //判断是否需要存在工天
      let st = row.startWorkTime
      const wt = row.WorkTime
      const lt = row.LimitTime
      const et = row.taskendtime
      let day = 0
      let st_Date, jt_Date
      if (st) {
        st_Date = new Date(st)
      } else {
        return 0
      }
      //存在工天，判断是否为
      if (wt || lt) {
        //判断项目是否完结
        jt_Date = new Date(st)
        jt_Date.setDate(jt_Date.getDate() + (lt ? lt : wt) + 1)
        const holidays = this.hasHoliday(st_Date, jt_Date)
        jt_Date.setDate(jt_Date.getDate() + holidays)
        if (et) {
          const et_Date = new Date(et)
          if (et_Date < jt_Date) {
            return 2
          } else {
            return -1
          }
        } else {
          if (jt_Date < new Date()) {
            return -1
          } else {
            return (jt_Date - new Date()) / (1 * 24 * 60 * 60 * 1000)
          }
        }
      }
      return day
    },
    hasHoliday(startTime, endTime) {
      let holidays = 0
      if (startTime < endTime) {
        while (startTime <= endTime) {
          if (this.holiday.length > 0) {
            const h = this.holiday.filter(
              (hd) =>
                hd.date ==
                startTime.getFullYear() +
                (startTime.getMonth() < 9 ? '0' : '') +
                (startTime.getMonth() + 1) +
                (startTime.getDate() < 10 ? '0' : '') +
                (startTime.getDate())
            )
            if (h.length > 0) {
              holidays++
            }
          } else {
            if (startTime.getDay() === 6 || startTime.getDay() === 0) {
              holidays++
            }
          }
          startTime.setDate(startTime.getDate() + 1)
        }
      }
      return holidays
    },
    async getHoliday() {
      const me = this
      const today = new Date()
      await this.$ajax
        .get(
          'https://api.apihubs.cn/holiday/get?year=' +
          today.getFullYear() +
          '&workday=2&order_by=1&size=366'
        )
        .then(function (response) {
          if (
            response.data &&
            response.data.msg &&
            response.data.msg === 'ok'
          ) {
            me.holiday = response.data.data.list
            //console.log(me.holiday)
          }
        })
    },
    setYwsc(index, row) {
      console.log(row)
      let me = this
      this.$ajax
        .get(
          me.$appConfig.apiUrls.ywxtApi +
          '/api/YW/SetFavorites?InstanceId=' +
          row.Id
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            ElMessage({
              type: 'success',
              message: response.data.Data ? '收藏成功' : '取消成功',
            })
            me.whereconditions()
            me.getTaskCount()
          } else {
            ElMessage({
              type: 'info',
              message: '收藏失败!',
            })
            console.log(response.data.errorMessage)
          }
        })
    },
    datePipe(timestamp, format = 'yyyy-MM-dd') {
      let date = new Date(timestamp);
      var o = {
        'y+': date.getFullYear(),
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };
      if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
          format = format.replace(
            RegExp.$1,
            RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
          );
        }
      }
      return format;
    }
  },
  computed: {
    conditions() {
      const conditions = [];
      let me = this
      for (let key in me.form) {
        if (key === 'taskendtime') {
          let rq = me.datePipe(me.form[key], 'yyyy-MM-dd') + ''
          if (rq == '1970-01-01') rq = 'NaN-aN-aN'
          // if (rq !== 'NaN-aN-aN') {
          //   conditions.push({
          //     Key: key,
          //     Value: me.searchtypeform[key] === 'like' ? "%" + rq + "%" : rq,
          //     IsCondition: true,
          //     Operator: me.searchtypeform[key],
          //     LogicalOperator: "and",
          //   });
          // }
        } else {
          conditions.push({
            Key: key,
            Value: me.searchtypeform[key] === 'like' ? "%" + me.form[key] + "%" : me.form[key],
            IsCondition: true,
            Operator: me.searchtypeform[key],
            LogicalOperator: "and",
          });
        }
      }
      let beginTime = this.datePipe(this.beginTime, 'yyyy-MM-dd')
      let endTime = this.datePipe(this.endTime, 'yyyy-MM-dd')
      if (beginTime == '' || beginTime == null || beginTime == 'NaN-aN-aN'||beginTime =='1970-01-01') {
        console.log(beginTime)
      } else {
        conditions.push({
          IsCondition: true,
          Key: "taskendtime",
          LogicalOperator: "and",
          Operator: ">=",
          Value: beginTime,
        })
      }
      if (endTime == '' || endTime == null || endTime == 'NaN-aN-aN' ||endTime =='1970-01-01') {
        console.log(endTime)
      } else {
        conditions.push({
          IsCondition: true,
          Key: "taskendtime",
          LogicalOperator: "and",
          Operator: "<=",
          Value: endTime,
        })
      }
      return conditions;
    },
  },
  activated() {
    this.$nextTick(() => {
      setTimeout(() => {
        var scrollTop = this.$el.querySelector('.el-table__body-wrapper')
        scrollTop.scrollTop = this.scrollTop
      }, 5)
    })
  },
  mounted() {
    this.fetch();
    this.getHoliday();
    this.$refs.UserTree.getOrganizeTree('0')
    this.$refs.UserTree3.getOrganizeTree('0')
    this.search(true);
  },
  deactivated() {
    // this.isHiding = false;
  },
};
</script>

<style scoped>
.hide1{
  transition: all 0.3s ease;
  height: 232px;
}
.hide2{
  transition: all 0.3s ease;
  height: 0;
  opacity: 0;
}
.edit-container {
  margin-top: 0px !important;
}
.buttons {
  position: relative;
  text-align: center;
  margin-bottom: 10px;
}
.el-icon-s-fold{
  color: #5c5a5a;
  padding: 2px;
  font-size: 34px;
  position: absolute;
  transition: 0.3s;
  transform: rotate(-270deg);
}
.is-collapse {
  transform: rotate(-90deg);
}

.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
:deep(.el-card__body) {
  padding-bottom: 0px;
}
</style>

<style>
.search-view .table-footer {
  border: none;
}

.search-view .el-form-item__content {
  display: flex;
  align-items: center;
}
.m-2 {
  width: 100px;
}
</style>